@import 'src/theme/colors.scss'

.item
  display: flex
  gap: 10px
  width: 100%
  justify-content: space-between
  align-items: center
  padding: 7px 10px 7px 10px
  border-radius: 8px
  border: 1px solid transparent
  margin-block: 1px
  background: var(--bg-color)
  outline: 0

.clickable
  cursor: pointer

.clickable.focused
  background: var(--active-bg-color)
  border-color: var(--active-border-color)

.item.active
  background: var(--active-bg-color)

.sticky
  position: sticky
  top: 0
  left: 0

.disabled
  pointer-events: none
  cursor: default

.color-primary
  --bg-color: transparent
  --hover-bg-color: #{$itemPrimaryHoverBgColor}
  --active-bg-color: #{$itemPrimaryActiveBgColor}
  --active-border-color: #{$itemPrimaryActiveColor}

.color-secondary
  --bg-color: transparent
  --hover-bg-color: #{$itemSecondaryHoverBgColor}
  --active-bg-color: #{$text15Color}
  --active-border-color: #{$inputMainColor}
